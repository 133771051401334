















import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import ReadingMaterialFormTemplate from '@/components/resources/ReadingMaterialFormTemplate.vue';
import { CourseStores } from '@/store/course';
import { DropdownItem } from '@/models/dropdown';
import { CreateReadingMaterial } from '@/models/reading_material';
import { ReadingMaterialStores } from '@/store/readingMaterials';
@Component({
    components: { Page, BackButtonLayout, ReadingMaterialFormTemplate },
})
export default class CreateResources extends Vue {
    // Stores
    courseListStore = CourseStores.list;
    readingMaterialListStore = ReadingMaterialStores.list;
    readingMaterialStateStore = ReadingMaterialStores.state;
    readingMaterialCreateStore = ReadingMaterialStores.create;
    // UI State
    courseData: DropdownItem[] = [];
    selectedCourseData: DropdownItem = {
        text: ``,
        value: ``,
        uniqueKey: ``
    };
    readingMaterialFolderData: DropdownItem[] = [];
    // Autofill
    readingMaterialCoursePrefixId = -1;
    readingMaterialFolderName = ``;

    get layoutTitle() {
        return `Upload Reading Material`;
    }

    get backRoute() {
        return `/resources`;
    }

    get courses() {
        return this.courseData;
    }

    get folders() {
        return this.readingMaterialFolderData;
    }

    mounted() {
        if (this.readingMaterialStateStore.readingMaterialFolderState.length === 3) {
            this.readingMaterialCoursePrefixId = this.readingMaterialStateStore.readingMaterialFolderState[this.readingMaterialStateStore.readingMaterialFolderState.length - 2].id;
            this.readingMaterialFolderName = this.readingMaterialStateStore.readingMaterialFolderState[this.readingMaterialStateStore.readingMaterialFolderState.length - 1].name;
        }

        if (this.readingMaterialStateStore.readingMaterialFolderState.length === 2) {
            this.readingMaterialCoursePrefixId = this.readingMaterialStateStore.readingMaterialFolderState[this.readingMaterialStateStore.readingMaterialFolderState.length - 1].id;
        }
        this.readingMaterialFolderData.push({
            text: `- Select -`,
            value: ``,
            uniqueKey: `select`,
        });
        this.courseListStore.retrieveAllSiteCoursesWithParams({});
    }

    fetchFolderLevels(payload: DropdownItem) {
        this.selectedCourseData = payload;
        this.readingMaterialListStore.retrieveReadingMaterialLevelsWithParams({ courseid: Number(payload.value)});
    }

    @Watch(`courseListStore.response`)
    updateCourseDropdownData() {
        if (this.courseListStore.response) {
            this.courseData = [];
            this.courseListStore.response.objects.forEach((data) => {
                this.courseData.push({
                    text: data.courseName,
                    value: data.id.toString(),
                    uniqueKey: `course_${data.courseName}`
                });
            });
        }
    }

    @Watch(`readingMaterialListStore.levelResponse`)
    updateReadingMaterialDropdownData() {
        if (this.readingMaterialListStore.levelResponse) {
            this.readingMaterialFolderData = [];
            this.readingMaterialFolderData.push({
                text: `- Select -`,
                value: ``,
                uniqueKey: `select`,
            });
            this.readingMaterialListStore.levelResponse.objects.forEach((data, index) => {
                this.readingMaterialFolderData.push({
                    text: data,
                    value: data,
                    uniqueKey: `folder_name_${index}`
                });
            });
        }
    }

    createReadingMaterial(payload: CreateReadingMaterial) {
        const formData = new FormData();
        if (typeof payload.readingMaterials !== 'string' && payload.readingMaterials ) {
            payload.readingMaterials.forEach((item) => {
                formData.append(`files[]`, item);
            })
        }
        if (typeof payload.thumbnailMaterial !== 'string' && payload.thumbnailMaterial ) {
            formData.append(`fileThumbnail`, payload.thumbnailMaterial);
        }
        formData.append(`fileName`, payload.name);
        formData.append(`courseId`, payload.courseId.toString());
        formData.append(`level`, payload.folderName);
        
        if (payload.isAvailable !== null) {
            payload.isAvailable === true ? formData.append(`availability`, 'true'): formData.append(`availability`, 'false');
        }

        this.readingMaterialCreateStore.createReadingMaterial(formData);
    }

    @Watch(`readingMaterialCreateStore.response`)
    redirectToResources() {
        if (this.readingMaterialCreateStore.response) {
            this.readingMaterialStateStore.updateReadingMaterialIsModified(true);
            this.$router.push({
                path: `/resources`
            });
        }
    }
}
